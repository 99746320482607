<template>
  <div :is="layout">
    <v-container>
      <div id="cesiumContainer"></div>
    </v-container>
  </div>
</template>
<script>
import flight from "../flightInformation";
export default {
  components: {
    flight,
  },
  data() {
    return {
      positions: [],
      controlId: "",
      layout: null
    };
  },
  mounted() {
    this.layout = this.$route.meta.use_layout
    this.controlId = this.$route.params.id;
    flight.flightInformation.forEach((item) => {
      if (item.id == this.controlId) {
        this.positions = item.control_area;
      }
    });
    this.$nextTick(() => {
      var viewer = new this.Cesium.Viewer("cesiumContainer");
       viewer.camera.setView({
            destination: this.Cesium.Cartesian3.fromDegrees(111.07, 39.05,10000000)
        });
      this.positions.forEach((control) => {
        viewer.entities.add({
          polygon: {
            hierarchy: {
              positions: this.Cesium.Cartesian3.fromDegreesArray(
                control.location
                // 管制区坐标
              ),
            },
            // material: this.Cesium.Color.BLUE.withAlpha(0.5),
            material: this.Cesium.Color.fromRandom({ alpha: 0.5 }),
            height: 100000,
            extrudedHeight: 0,
            outline: true,
            outlineColor: this.Cesium.Color.BLACK,
          },
        });
      });
    });
  },
};
</script>
<style scoped>
#cesiumContainer {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
</style>
